<template>
  <v-row>
    <v-col
      cols="12"
      sm="8"
      md="6"
      class="request-comments"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title class="white--text">
            Comments
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            class="white--text mr-2"
            icon
          >
            <v-icon @click="isCommentDialogVisible = true">
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-list
          v-if="comments && comments.length > 0"
          three-line
          disabled
        >
          <v-list-item-group>
            <template v-for="(item, index) in comments">
              <v-list-item :key="`comment_${index}`">
                <v-list-item-content v-if="item.user">
                  <v-list-item-title class="mb-3 comment-remarks">{{item.comment}}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.user.nickname || item.user.name }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="comment-publish-date">{{item.published_at}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="index < comments.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-card-text
          v-else
          class="py-5"
        >
          <div>No comments available</div>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Comment dialog -->
    <v-dialog
      v-model="isCommentDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          New comment
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isCommentDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here"
            :disabled="loading"
          ></v-textarea>
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="createComment()"
          >
            Save comment
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiClose,
} from '@mdi/js'
import {
  inject,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    fetchComments: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const { router } = useRouter()
    const requestId = router.currentRoute.params.request_id

    const newComment = ref(null)
    const loading = ref(false)
    const isCommentDialogVisible = ref(false)
    const userData = JSON.parse(localStorage.getItem('userData'))

    const createComment = () => {
      loading.value = true
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: requestId,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          loading.value = false
          isCommentDialogVisible.value = false
          props.fetchComments()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }

    return {
      newComment,
      loading,
      isCommentDialogVisible,
      createComment,
      icons: {
        mdiPlus,
        mdiClose,
      },
    }
  },
}
</script>

<style scoped>
.request-comments {
  min-width: 100%;
}
.comment-remarks {
  word-wrap: break-word !important;
  white-space: break-spaces;
  font-size: 13px;
  line-height: 18px;
}
.comment-publish-date {
  margin-top: 5px;
  font-size: 10px;
}
</style>
