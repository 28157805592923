<template>
  <div>
    <v-alert
      v-for="(message, index) in messages"
      :key="message"
      outlined
      text
      :color="color"
      prominent
      :type="type"
      :class="{ 'mb-0': index + 1 === messages.length }"
    >
      <div class="pl-3">
        {{ message }}
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      required: true,
    },
  },
}
</script>
