import { axios, baseURL } from '@axios'
import encryptionService from '@/services/encryption.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/invoices', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentMethods() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/payment_methods')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInsuranceCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/categories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInsuranceProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/products')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInsurances() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/insurances')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBluecardFinances() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/bluecard_finances')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPickupLocations() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/variables/pickup_locations')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/invoices', { invoice: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/invoices/${params.id}`, { invoice: params.invoice })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    voidInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReport(ctx, params) {
      return new Promise((resolve, reject) => {
        const urlParams = new URLSearchParams()
        urlParams.append('search_text', params.search_text)
        params.branch_ids.forEach(branchId => urlParams.append('branch_ids[]', branchId))
        urlParams.append('start_date', params.start_date)
        urlParams.append('end_date', params.end_date)
        urlParams.append('status', params.status)
        urlParams.append('branch_toggle_filter', params.branch_toggle_filter)
        urlParams.append('mode', params.mode)
        if (params.spd_list !== undefined) urlParams.append('spd_list', params.spd_list)

        fetch(`${baseURL}/api/v1/invoices.${params.format}?${urlParams}`, {
          method: 'GET',
          headers: JSON.parse(encryptionService.decrypt(localStorage.getItem('authHeaders'))),
        })
          .then(response => resolve(response.blob()))
          .catch(error => reject(error))
      })
    },
  },
}
