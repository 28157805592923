<template>
  <div v-if="isAttrChange && isAuditorRespond && !isRequestVoid">
    <small class="pl-2">
      change to
    </small>
    <small
      v-if="link === ''"
      class="primary--text font-weight-semibold"
    >
      {{ hint || 'Empty' }}
    </small>
    <a
      v-else
      :href="link"
      target="_blank"
      class="font-weight-semibold"
    >
      <small>{{ hint }}</small>
      <v-icon
        size="18"
        color="primary"
      >{{ icons.mdiOpenInNew }}</v-icon>
    </a>
  </div>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'

export default {
  props: {
    isAttrChange: {
      type: Boolean,
      default: () => false,
    },
    isAuditorRespond: {
      type: Boolean,
      default: () => false,
    },
    hint: {
      type: String,
      default: () => '',
    },
    link: {
      type: String,
      default: () => '',
    },
    isRequestVoid: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew,
      },
    }
  },
}
</script>
