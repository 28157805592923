<template>
  <div>
    <v-img
      :src="require('@/assets/images/letterhead.png')"
      class="mx-auto mb-2"
    >
      <template v-slot:placeholder>
        <v-sheet>
          <v-skeleton-loader type="image" />
        </v-sheet>
      </template>
    </v-img>

    <!-- <v-row
      v-if="organization && $vuetify.breakpoint.smAndUp"
      class="text-center mt-2"
    >
      <v-col class="pt-0 pb-2">
        <p class="mb-0">
          Brunei Muara/Temburong Quotation <br> +673 {{ organization.quotation_center_1 }}
        </p>
      </v-col>
      <v-col class="pt-0 pb-2">
        <p class="mb-0">
          Tutong/Belait Quotation <br> +673 {{ organization.quotation_center_2 }}
        </p>
      </v-col>
      <v-col class="pt-0 pb-2">
        <p class="mb-0">
          Customer Careline (general inquiry) <br> +673 {{ organization.customer_careline_contact }}
        </p>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { inject, computed } from '@vue/composition-api'
import { VSkeletonLoader } from 'vuetify/lib'

export default {
  components: {
    VSkeletonLoader,
  },
  setup() {
    // Initializers
    const store = inject('store')

    // Computed
    const organization = computed(() => store.state.organization)

    return {
      organization,
    }
  },
}
</script>

<style scoped>
  p {
    font-size: 9px !important;
  }
</style>
